<template>
  <div class="role-container white">
    <filter-bar :loading="loading" @searched="filter" @refresh="$apollo.queries.shipments.refresh()">

    </filter-bar>
    <v-layout
      row align-start justify-start nowrap
      id="list-group-container"
    >
      <list
        v-for="list in listConfig"
        :name="list.name"
        :orders="filterList(shipments, list.status)"
        :status="list.status"
        :helpText="list.helpText"
        :key="list.name"
        @dragged="moveOrder"
      >
        <template #card="{ order }">
          <shipping-card :order="order" @manage="prepDialog" @list="printPickList" @slip="printPackSlip"/>
        </template>
      </list>
    </v-layout>
    <v-dialog
      v-model="showDialog"
      lazy
      persistent
      scrollable
      :max-width="printDialog.show ? '500px' : ''"
      :fullscreen="eventOrder.isInternational"
    >
      <shipment-order-management
        v-if="dialog"
        :id="eventOrder.id"
        :label="eventOrder.label"
        @close="dialog = false"
      />
      <print-dialog @updated="printer = $event.value" v-if="printDialog.show">
        <template #title>
          {{ printDialog.title }}
        </template>
        <template #actions>
          <cancel-button @click="resetPrintDialog" class="mr-2"/>
          <submit-button
            :loading="printing"
            :disabled="!printer"
            @click="printDialog.handler()"
          >
            <template #label>Print</template>
          </submit-button>
        </template>
      </print-dialog>
    </v-dialog>
  </div>
</template>

<script>
import list from '@/components/boards/list.vue'
import filterBar from '@/components/bars/filterbar.vue'
import { taskList } from '@/components/mixins/taskList'
import { GET_SHIPMENT_ORDERS } from '@/api/graphql/Constants/Shipments'
import { SHIPMENT_ORDER_STATUS } from '@/models/ExtraBackendModels'
import { ScrollHorizontalWithWheel } from '@/lib/helpers'
import shippingCard from '@/components/cards/shippingCard'
import shipmentOrderManagement from '@/components/dialogs/shipmentOrderManagement'
import { PrintPackList, PrintPickListForSt } from '@/api/graphql/Constants/printNode'
import PrintDialog from '@/components/dialogs/PrintDialog'
import SubmitButton from '@/components/buttons/SubmitButton'
import CancelButton from '@/components/buttons/CancelButton'
export default {
  name: 'shipping',
  mixins: [taskList],
  components: {
    list,
    filterBar,
    'shipping-card': shippingCard,
    'shipment-order-management': shipmentOrderManagement,
    'print-dialog': PrintDialog,
    'submit-button': SubmitButton,
    'cancel-button': CancelButton
  },
  computed: {
    user () {
      return this.$store.state.profile.user
    },

    showDialog () {
      return this.dialog || this.printDialog.show
    },

    sortConfig () {
      return this.$store.state.orders.listSortConfig
    }
  },
  data () {
    return {
      shipments: [],
      listConfig: [
        { name: 'To pack', status: SHIPMENT_ORDER_STATUS.TO_PACK },
        { name: 'Packing...', status: SHIPMENT_ORDER_STATUS.PACKING },
        { name: 'Label needed', status: SHIPMENT_ORDER_STATUS.TO_PROCESS },
        { name: 'Awaiting Carrier', status: SHIPMENT_ORDER_STATUS.AWAITING_CARRIER }
      ],
      searchable: '',
      sortable: 'id',
      inProcessingCount: 0,

      loading: false,
      eventOrder: { id: 0, isInternational: false, label: '' },
      dialog: false,
      printDialog: {
        show: false,
        title: '',
        handler: null
      },
      printing: false,
      printer: 0
    }
  },
  apollo: {
    shipments: {
      query: GET_SHIPMENT_ORDERS,
      variables: {
        input: {
          annotations: [
            {
              name: 'hasBattery',
              aggr_field: 'attached_sale__st_items__part__has_battery',
              aggr_filters: [{ key: 'attached_sale__st_items__part__has_battery', value: true }],
              aggr_type: 'COUNT'
            }
          ],
          filters: [
            {
              key: 'attached_purchase__isnull',
              value: true
            },
            {
              key: 'attached_sale__isnull',
              value: false,
              or: {
                key: 'attached_purchase_rma__isnull',
                value: false,
                or: {
                  key: 'attached_rma__isnull',
                  value: false
                }
              }
            }
          ]
        }
      },
      update: data => {
        if (data.shipments) {
          return data.shipments.map(s => {
            let hasBattery = false
            const hasBatteryAnnotation = s.annotations?.find(a => a.name === 'hasBattery') ?? null
            if (hasBatteryAnnotation) {
              hasBattery = Number(hasBatteryAnnotation.value) > 0
            }
            let service = ''
            let carrier = ''
            let color = ''
            if (s.service) {
              service = s.service.service
              color = s.service.color
              if (!color.includes('#')) {
                color = `#${color}`
              }
              carrier = s.service.carrier.name
            } else if (s.deliver_by_date) {
              service = 'Deliver By: ' + s.deliver_by_date
            } else if (s.pickup_type === 'NOT_PICKUP') {
              service = 'Cheapest'
            } else {
              service = s.pickup_type
            }
            if (s.sale) {
              return {
                id: s.id,
                label: `ST: ${s.sale.id}`,
                transactionId: s.sale.id,
                status: `${s.status.id}`,
                rep: s.sale.rep.initials,
                assignee: s.assignee?.initials ?? false,
                assigneeColor: s.assignee?.profile?.color ?? '',
                service: service,
                color: color,
                carrier: carrier,
                battery: hasBattery,
                isCourier: s.pickup_type !== 'NOT_PICKUP',
                shipments: s.shipments,
                isInternational: s.isInternational
              }
            } else if (s.srma) {
              return {
                id: s.id,
                label: `ST RMA: ${s.srma.id}`,
                transactionId: s.srma.id,
                status: `${s.status.id}`,
                rep: s.srma.order.rep.initials,
                assignee: s.assignee?.initials ?? false,
                assigneeColor: s.assignee?.profile?.color ?? '',
                service: service,
                color: color,
                carrier: carrier,
                battery: false, // TODO:
                shipments: s.shipments,
                isInternational: s.isInternational
              }
            } else if (s.prma) {
              return {
                id: s.id,
                label: `PT RMA: ${s.id.prma.id}`,
                transactionId: s.id.prma.id,
                status: `${s.status.id}`,
                rep: s.prma.order.rep.initials,
                assignee: s.assignee?.initials ?? false,
                assigneeColor: s.assignee?.profile?.color ?? '',
                service: service,
                color: color,
                carrier: carrier,
                battery: false, // TODO:
                shipments: s.shipments,
                isInternational: s.isInternational
              }
            }
          })
        }
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
    // inProcessingCount: {
    //   query: GET_SHIPMENT_ORDERS,
    //   variables: {
    //     input: {
    //       limit: 10000,
    //       filters: [
    //         { key: 'status__id', value: SHIPMENT_ORDER_STATUS.CREATED },
    //         {
    //           key: 'attached_purchase__isnull',
    //           value: true
    //         },
    //         {
    //           key: 'attached_sale__isnull',
    //           value: false,
    //           and: { key: 'attached_sale__status__id', value: SALES_ORDER_STATUS.TO_PICK },
    //           or: {
    //             key: 'attached_purchase_rma__isnull',
    //             value: false,
    //             and: { key: 'attached_purchase_rma__status__id', value:  }
    //             or: {
    //               key: 'attached_rma__isnull',
    //               value: false
    //             }
    //           }
    //         }
    //       ]
    //     }
    //   },
    //   update: data => data.shipments.length
    // }
  },
  methods: {
    filterList (orders, status) {
      // return a list that is filtered by status and by searchables.searched
      const list = []
      let checker = false

      for (const order of orders) {
        if (order.status.toString() === status) {
          for (const key in order) {
            if ((String(order[key]).toLowerCase()).indexOf(this.searchable.toLowerCase()) > -1) {
              checker = true
            }
          }
          if (checker) {
            list.push(order)
            checker = false
          }
        }
      }
      return list.sort((a, b) => {
        return this.sortConfig.descending ? a[this.sortConfig.sortBy.field] - b[this.sortConfig.sortBy.field] : b[this.sortConfig.sortBy.field] - a[this.sortConfig.sortBy.field]
      })
    },

    filter (searchable) {
      this.searchable = searchable
    },

    prepDialog (event) {
      this.eventOrder = event.value
      this.dialog = true
    },

    resetDialog () {
      this.dialog = false
    },

    resetPrintDialog () {
      this.printDialog.show = false
      this.printDialog.title = ''
      this.printDialog.handler = null
    },

    printPickList (event) {
      this.eventOrder = event.value
      this.printDialog.title = 'Print Pick List for Order: ' + this.eventOrder.transactionId
      this.printDialog.handler = async () => {
        if (this.printer) {
          this.printing = true
          try {
            await PrintPickListForSt(this.eventOrder, this.printer)
          } catch (e) {
            this.$store.dispatch('notifications/createSnackbar', {
              message: e.message || 'Could not print pick list.',
              color: 'error',
              timeout: 4000
            })
          } finally {
            this.printing = false
          }
        } else {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Printer could not be used',
            color: 'error',
            timeout: 4000
          })
        }
      }
      this.printDialog.show = true
    },

    printPackSlip (event) {
      this.eventOrder = event.value
      this.printDialog.title = 'Print Pack Slip for Order: ' + this.eventOrder.transactionId
      this.printDialog.handler = async () => {
        if (this.printer) {
          this.printing = true
          try {
            await PrintPackList(this.eventOrder, this.printer)
          } catch {
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'Could not print pack slip',
              color: 'error',
              timeout: 4000
            })
          } finally {
            this.printing = false
          }
        } else {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Printer could not be used',
            color: 'error',
            timeout: 4000
          })
        }
      }
      this.printDialog.show = true
    },

    async moveOrder (order, status) {
      for (let i = 0; i < this.shipments.length; i++) {
        if (this.shipments[i].id === order.id) {
          const st = this.shipments[i]
          st.status = status
          st.assignee = await this.moveShipment(st.id, status)
          break
        }
      }
    }
  },
  mounted () {
    // this.$apollo.queries.shipments.startPolling(15000)
    this.$nextTick(() => {
      const el = document.getElementsByClassName('role-container')
      if (el[0]) {
        el[0].addEventListener('mousewheel', ScrollHorizontalWithWheel)
      }
    })
  },
  beforeDestroy () {
    // this.$apollo.queries.shipments.stopPolling()
    const el = document.getElementsByClassName('role-container')
    if (el[0]) {
      el[0].removeEventListener('mousewheel', ScrollHorizontalWithWheel)
    }
  }
}
</script>
