<template>
  <v-card min-height="790px">
    <v-card-title class="brown" style="max-height: 58px">
      <v-layout row justify-space-between align-center>
        <h3
          v-if="isElectron && order.isInternational"
          class="white--text"
          style="margin-left: 70px"
        >
          Shipment Order Management
        </h3>
        <h3 v-else class="white--text">Shipment Order Management</h3>
        <h4 class="white--text" v-if="isDev">{{ breakpoint }}</h4>
        <h4 class="white--text">{{ this.label }}</h4>
      </v-layout>
    </v-card-title>
    <v-progress-linear
      class="mt-0"
      v-if="!dataIsSet"
      indeterminate
      color="info"
    />
    <v-scale-transition>
      <v-card-text v-if="dataIsSet" class="pt-0">
        <v-container fluid pa-0>
          <v-tabs v-model="tabs" centered slider-color="blue">
            <v-tab ripple>Box Management</v-tab>
            <v-tab ripple v-if="!orderIsProcessed">New Box</v-tab>

            <v-tabs-items>
              <!-- Box Info -->
              <v-tab-item>
                <v-container grid-list-xl>
                  <v-card>
                    <v-data-table
                      :items="shipments"
                      :headers="headers"
                      :rows-per-page-items="[5, 10]"
                    >
                      <template v-slot:items="{ item }">
                        <td>{{ item.box_number }}</td>
                        <td>{{ item.weight / 16 }} lbs</td>
                        <td v-if="item.predefined">{{ item.predefined }}</td>
                        <td v-else>
                          {{ `${item.length}x${item.width}x${item.height} in` }}
                        </td>
                        <td>
                          <v-progress-circular
                            color="primary"
                            indeterminate
                            v-if="item.loadingRate"
                          />
                          <span v-else>{{ item.rate.rate || "-" }}</span>
                        </td>
                        <td class="justify-start layout px-0">
                          <v-tooltip top :disabled="printer > 0">
                            <template #activator="{ on }">
                              <div v-on="on">
                              <v-btn
                                fab
                                icon
                                small
                                :loading="item.isPrintingLabel"
                                :disabled="!printer"
                                @click="printShipmentLabel(item)"
                              >
                                <v-icon color="brown"
                                  >fal fa-barcode-alt</v-icon
                                >
                              </v-btn>
                              </div>
                            </template>
                            <span>Select a printer to print labels!</span>
                          </v-tooltip>
                          <v-btn
                            v-if="order.isInternational"
                            @click="printShipmentCommercialInvoice(item)"
                            fab
                            icon
                            small
                            :loading="item.isPrintingCommercialInvoice"
                            :disabled="!printer"
                          >
                            <v-icon color="primary">fal fa-file-invoice</v-icon>
                          </v-btn>
                          <v-btn
                            :disabled="orderIsProcessed"
                            :flat="!item.confirm"
                            color="error"
                            :loading="item.removing"
                            @click="removeBox({ box: item })"
                          >
                            {{ item.confirm ? "Confirm" : "Delete" }}
                          </v-btn>
                        </td>
                      </template>
                      <template v-slot:actions-append>
                        <v-btn @click="tabs = 1" :disabled="orderIsProcessed">
                          <v-icon class="pr-2" color="info">fal fa-plus</v-icon
                          >New Box
                        </v-btn>
                      </template>
                      <template #footer>
                        <tr>
                          <td colspan="3"><b>Totals:</b></td>
                          <td>
                            <span>{{ totalRate }}</span>
                          </td>
                          <td></td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-container>
              </v-tab-item>
              <!-- New Box -->
              <v-tab-item>
                <v-container grid-list-xl py-0 fluid>
                  <v-form ref="newBox" class="pt-3">
                    <v-container pb-0 fluid>
                      <v-layout row wrap justify-center align-start>
                        <!-- Predefined Switch -->
                        <v-flex xs6 sm4 md3>
                          <v-switch
                            label="Carrier Packaging?"
                            v-model="usePredefined"
                          />
                        </v-flex>
                        <!-- Weight -->
                        <v-flex xs6 sm3 md2>
                          <v-text-field
                            v-model.number="weight"
                            label="Weight"
                            suffix="lbs"
                            :rules="weightRules"
                            box
                          />
                        </v-flex>
                        <!-- Length -->
                        <v-flex xs6 sm4 md2 pl-2 v-if="!usePredefined">
                          <v-text-field
                            v-model.number="length"
                            label="Length"
                            box
                            suffix="in."
                            :rules="dimRules"
                          />
                        </v-flex>
                        <!-- Width -->
                        <v-flex xs6 sm4 md2 pl-2 v-if="!usePredefined">
                          <v-text-field
                            v-model.number="width"
                            label="Width"
                            box
                            suffix="in."
                            :rules="dimRules"
                          />
                        </v-flex>
                        <!-- Height -->
                        <v-flex xs6 sm4 md2 pl-2 v-if="!usePredefined">
                          <v-text-field
                            v-model.number="height"
                            label="Height"
                            box
                            suffix="in."
                            :rules="dimRules"
                          />
                        </v-flex>
                        <!-- Predefined Parcels -->
                        <v-flex xs12 sm8 md6 v-if="usePredefined">
                          <v-select
                            :items="epPredefineds"
                            v-model="predefinedParcel"
                            :rules="predefinedRules"
                          >
                            <template #label>
                              {{ order.service.shipper.name }} Predefined
                            </template>
                          </v-select>
                        </v-flex>

                        <!-- INTERNATIONAL INFO -->
                        <!-- Restriction Type -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-select
                            v-model="restrictionType"
                            :items="[
                              'none',
                              'other',
                              'quarantine',
                              'sanitary_phytosanitary_inspection',
                            ]"
                            label="Restriction Type"
                            hint="Does this shipment require special treatment on import?"
                            persistent-hint
                          />
                        </v-flex>
                        <!-- Restriction Comment -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-textarea
                            v-model="restrictionComment"
                            label="Restriction Comment"
                            :placeholder="
                              restrictionType !== 'none' ? 'Required' : ''
                            "
                            :disabled="restrictionType === 'none'"
                            no-resize
                            box
                            :rules="[
                              (e) =>
                                restrictionType === 'none' ||
                                !!e ||
                                'This is required when restriction type is not none',
                            ]"
                          />
                        </v-flex>
                        <!-- Content Type -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-select
                            v-model="contentsType"
                            :items="[
                              'documents',
                              'gifts',
                              'merchandise',
                              'returned_goods',
                              'sample',
                              'other',
                            ]"
                            label="Content Type"
                            :rules="[(e) => !!e || 'This is required']"
                          />
                        </v-flex>
                        <!-- Content Explanation -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-textarea
                            v-model="contentsExplanation"
                            label="Explanation"
                            placeholder="Required"
                            no-resize
                            box
                            :rules="[(e) => !!e || 'This is required']"
                          />
                        </v-flex>
                        <!-- Non Delivery Option -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-select
                            v-model="nonDeliveryOption"
                            :items="['abandon', 'return']"
                            label="Non Delivery Option"
                          />
                        </v-flex>
                        <!-- Customs Certify -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-checkbox
                            v-model="customsCertify"
                            label="Electronically Certify"
                            color="red"
                          />
                        </v-flex>
                        <!-- Customs Signer -->
                        <v-flex xs3 v-if="order.isInternational">
                          <v-text-field
                            v-model="customSigner"
                            label="Signer"
                            placeholder="Required"
                            :disabled="!customsCertify"
                            box
                            :rules="[
                              (e) =>
                                !customsCertify ||
                                !!e ||
                                'This is required when e-certifying',
                            ]"
                          />
                        </v-flex>
                        <!-- Aes Number -->
                        <v-flex xs3 v-if="aesNumberNeeded">
                          <v-text-field
                            v-model="aesNumber"
                            label="AES/ITN Number"
                            box
                            :placeholder="
                              aesNumberNeeded ? 'Required' : 'Not required'
                            "
                            :rules="[
                              (e) => (aesNumberNeeded && !!e) || 'Required',
                            ]"
                          />
                        </v-flex>

                        <!-- Hazmat -->
                        <v-flex xs6 sm3>
                          <v-select
                            v-model="hazmat"
                            :items="[
                              'LIMITED_QUANTITY',
                              'LITHIUM',
                              'ORMD',
                              'PRIMARY',
                              'PRIMARY_CONTAINED',
                              'PRIMARY_PACKED',
                              'SECONDARY',
                              'SECONDARY_CONTAINED',
                              'SECONDARY_PACKED',
                            ]"
                            label="Dangerous Goods"
                            color="green"
                            clearable
                          />
                        </v-flex>
                        <!-- Delivery Confirmation -->
                        <v-flex xs5 sm3>
                          <v-select
                            v-model="deliveryConfirmation"
                            :items="deliveryConfirmations"
                            color="blue"
                            label="Delivery Confirmation"
                            :rules="[(e) => !!e || 'Required']"
                          />
                        </v-flex>
                        <!-- Hold for Pickup -->
                        <v-flex xs5 sm3>
                          <v-checkbox
                            v-model="holdForPickup"
                            label="Hold for Pickup"
                            color="blue"
                          />
                        </v-flex>
                        <!-- Drop Off Type -->
                        <v-flex xs5 sm3>
                          <v-select
                            v-model="dropOffType"
                            :items="dropOffTypes"
                            color="primary"
                            label="Drop Off Type"
                            :rules="[(e) => !!e || 'Required']"
                          />
                        </v-flex>
                        <!-- Invoice Number -->
                        <v-flex xs4 md3>
                          <v-text-field
                            v-model="invoiceNumber"
                            label="Invoice Number"
                            placeholder="Optional"
                            hint="This shows up on the label!"
                            box
                          />
                        </v-flex>
                        <!-- Is Return Shipment
                          <v-flex xs4 sm3>
                            <v-checkbox
                              v-model="isReturnShipment"
                              color="blue"
                              label="Return shipment?"
                            />
                          </v-flex> -->
                      </v-layout>
                    </v-container>
                  </v-form>
                  <v-container grid-list-xl pa-0 fluid>
                    <v-layout row wrap justify-center align-center>
                      <!-- Customs Items Table -->
                      <v-flex xs12 v-if="order.isInternational">
                        <v-data-table
                          v-model="selected"
                          :headers="customsItemsHeaders"
                          :items="customsItems"
                          item-key="pn"
                          class="elevation-4"
                        >
                          <template v-slot:items="props">
                            <td>
                              <v-checkbox
                                v-model="props.selected"
                                color="brown"
                                hide-details
                              />
                            </td>
                            <td>{{ props.item.pn }}</td>
                            <td>
                              <v-edit-dialog
                                lazy
                                transition="slide-y-transition"
                                @open="focusElement('desc-input')"
                                @close="unFocusElement('desc-input')"
                                >{{ props.item.description }}
                                <v-icon
                                  class="pl-2"
                                  small
                                  :color="
                                    props.item.description ? '' : 'warning'
                                  "
                                  >fal fa-pencil</v-icon
                                >
                                <template v-slot:input>
                                  <v-text-field
                                    v-model.trim="props.item.description"
                                    id="desc-input"
                                    :rules="[
                                      (e) => e.length > 0 || 'This is required',
                                    ]"
                                    label="Customs Item Description"
                                    single-line
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td>
                              <v-edit-dialog
                                lazy
                                transition="slide-y-transition"
                                @open="focusElement('tariff-input')"
                                @close="unFocusElement('tariff-input')"
                              >
                                {{ props.item.hs_tariff_number }}
                                <v-icon
                                  class="pl-2"
                                  small
                                  :color="
                                    props.item.hs_tariff_number ? '' : 'warning'
                                  "
                                  >fal fa-pencil</v-icon
                                >
                                <template v-slot:input>
                                  <v-text-field
                                    v-model.trim="props.item.hs_tariff_number"
                                    id="tariff-input"
                                    :rules="[(e) => !!e || 'This is required']"
                                    label="Customs HTS Code"
                                    single-line
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td>{{ props.item.value }}</td>
                            <td>{{ props.item.quantity }}</td>
                            <td>
                              <v-edit-dialog
                                lazy
                                transition="slide-y-transition"
                                @open="focusElement('weight-input')"
                                @close="unFocusElement('weight-input')"
                              >
                                <span style="min-width: 60px"
                                  >{{ props.item.weight }} lbs</span
                                >
                                <v-icon
                                  class="pl-2"
                                  small
                                  :color="props.item.weight ? '' : 'warning'"
                                  >fal fa-pencil</v-icon
                                >
                                <template v-slot:input>
                                  <v-text-field
                                    v-model.number="props.item.weight"
                                    id="weight-input"
                                    :rules="[(e) => e > 0 || 'Must be > 0']"
                                    label="Weight"
                                    single-line
                                    autofocus
                                    suffix="lbs"
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                            <td>{{ props.item.origin_country }}</td>
                          </template>
                        </v-data-table>
                        <span
                          class="error--text bold"
                          v-if="customsItemsError"
                          >{{ customsItemsError }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-card-text>
    </v-scale-transition>
    <v-card-actions class="align-end py-0">
      <v-container mb-0 py-0 grid-list-xl fluid>
        <v-layout row wrap justify-start align-end fill-height>
          <!-- Add Button -->
          <v-slide-y-reverse-transition>
            <v-flex xs12 class="text-xs-center" v-show="tabs === 1">
              <v-btn
                block
                color="info"
                :loading="addingBox"
                :disabled="addingBox"
                @click="addBox()"
                >Add Box</v-btn
              >
            </v-flex>
          </v-slide-y-reverse-transition>
          <!-- Comments -->
          <!-- Shipping Comments -->
          <v-flex xs6 v-if="dataIsSet">
            <v-textarea
              v-model="order.comments"
              label="Shipping Comments"
              box
              readonly
              no-resize
            />
          </v-flex>
          <!-- Order Comments -->
          <v-flex xs6 v-if="dataIsSet">
            <v-textarea
              v-model="order.sale.comments"
              label="Order Comments"
              box
              readonly
              no-resize
            />
          </v-flex>
        </v-layout>
        <v-layout row justify-end align-center>
          <!-- Printers -->
          <v-flex class="px-2" xs4>
            <printers :printer="printer" @updated="printer = $event.value">
              <template #label> Printer select... </template>
            </printers>
          </v-flex>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">Close</v-btn>
          <v-btn
            v-if="!orderIsProcessed"
            @click="process"
            color="success"
            :loading="processing"
            :disabled="processing || succeeded"
            >Process Shipment</v-btn
          >
          <v-btn
            v-else
            @click="refund"
            color="error"
            flat
            :loading="refunding"
            :disabled="!canRefund"
            >Refund</v-btn
          >
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import mutateShipments from '@/api/graphql/mutationsJS/mutateShipments'
import Vue from 'vue'
import { mapActions } from 'vuex'
import printers from '@/components/autocompletes/printers'
import {
  GET_SHIPMENT_ORDER_DETAILS__FULL,
  GET_SHIPMENTS_ON_SHIPPING_ORDER
} from '@/api/graphql/Constants/Shipments'
import { GET_SOLD_ITEMS_FOR_CUSTOMS } from '@/api/graphql/Constants/Orders'
import { dateIsSameOrBefore } from '@/api/helpers'
import {
  BuyEasyPostShipments,
  GET_EASY_POST_SHIPMENT_FULL,
  GetEasyPostShipmentCommercialInvoice,
  GetEasyPostShipmentLabel,
  RefundEasyPostShipments
} from '@/api/graphql/Constants/EasyPost'
import { GetShipmentRateForService } from '@/api/graphql/Constants/EasyPost'
import { FormatMoney } from '@/lib/PdfPrep'
import {
  CreatePrintJob,
  PrintLabelsFromUri
} from '@/api/graphql/Constants/printNode'
import { SHIPMENT_ORDER_STATUS } from '@/models/ExtraBackendModels'
import {
  Printnode_Content_Type
} from '@/models/generated/graphql/ErpBackend'
import { freshDeskWidget } from '@/lib/freshDesk.ts'
export default {
  name: 'shipmentOrderManagement',
  components: {
    printers: printers
  },
  mixins: [mutateShipments],
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    orderIsProcessed () {
      return (
        `${this.order?.status?.id}` ===
          SHIPMENT_ORDER_STATUS.AWAITING_CARRIER ||
        `${this.order?.status?.id}` ===
          SHIPMENT_ORDER_STATUS.PICKED_UP_BY_CARRIER
      )
    },

    canRefund () {
      return true // this.$store.state.profile.permissions or something
    },

    additionalHandling () {
      return (
        this.length > 60 ||
        this.width > 60 ||
        this.height > 60 ||
        this.weight > 70
      )
    },

    nextBoxNumber () {
      return (
        this.shipments
          .map((s) => s.box_number)
          .sort()
          .reverse()[0] + 1 || 1
      )
    },

    totalRate () {
      let total = 0
      for (const shipment of this.shipments) {
        if (shipment.rate.id) {
          total += Number(shipment.rate.rate)
        }
      }
      return FormatMoney(total, 'USD')
    },

    weightOunces () {
      return this.weight * 16
    },

    breakpoint () {
      return this.$vuetify?.breakpoint?.name ?? ''
    },

    isDev () {
      return this.$store.state.profile.user.isSuperuser
    },

    isElectron () {
      return navigator.userAgent.includes('Electron')
    },

    easyPostDropOffType () {
      return this.dropOffType.replace(' ', '_')
    },

    easyPostPaymentType () {
      if (this.order.wePay) {
        // if we pay
        return 'SENDER'
      } else {
        if (this.order.blind) {
          // if we don't pay and blind
          return 'THIRD_PARTY'
        } else {
          // if we don't pay and not blind
          return 'RECEIVER'
        }
      }
    },

    epPredefineds () {
      const predefineds = require('@/assets/shipping-files/predefined_packages.json')
      return (
        predefineds[this.order?.service?.shipper?.name] ?? [
          'Could not get predefined packages'
        ]
      )
    },

    originCountry () {
      return 'US'
    },

    aesNumberNeeded () {
      let total = 0
      for (const item of this.customsItems) {
        total = total + item.value
      }
      return total >= 2500 && this.order.isInternational
    }
  },
  watch: {
    customsCertify (value) {
      if (value) {
        this.customSigner = `${this.$store.state.profile.user.firstName} ${this.$store.state.profile.user.lastName}`
      } else {
        this.customSigner = ''
      }
    },
    deliveryConfirmation (value) {
      if (value === undefined) this.deliveryConfirmation = 'NO_SIGNATURE'
    },
    dropOffType (value) {
      if (value === undefined) this.dropOffType = this.dropOffTypes[0]
    }
  },
  data () {
    return {
      order: {},
      shipments: [],
      serviceEasypostName: '',
      tabs: 0,

      printer: 0,
      labelsToPrint: [],

      alertMessage: '',
      alertType: 'success',
      dataIsSet: false,
      addingBox: false,
      removingBox: false,
      processing: false,
      refunding: false,
      succeeded: false,

      // new box headers
      headers: [
        { text: 'Box #', value: 'index' },
        { text: 'Weight', value: 'weight' },
        { text: 'LxWxH', value: 'dims' },
        { text: 'Rate', value: 'rate.rate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],

      // new box variables
      weight: '',
      length: '',
      width: '',
      height: '',
      predefinedParcel: '',
      usePredefined: false,

      weightRules: [
        (e) => {
          return e > 0 || 'Weight must be more than zero'
        }
      ],

      dimRules: [
        (e) =>
          (e > 0 && !this.usePredefined) || 'Dimensions must be more than zero'
      ],

      predefinedRules: [
        (e) => (!!e && this.usePredefined) || 'Predefined is required.'
      ],

      isReturnShipment: false,

      // easy post options
      // currency
      deliveryConfirmation: 'NO_SIGNATURE',
      deliveryConfirmations: [
        'ADULT_SIGNATURE',
        'INDIRECT_SIGNATURE',
        'NO_SIGNATURE',
        'SIGNATURE'
      ],
      dropOffType: 'REGULAR PICKUP',
      dropOffTypes: [
        'REGULAR PICKUP',
        'SCHEDULED PICKUP',
        'RETAIL LOCATION',
        'STATION',
        'DROP BOX'
      ],
      holdForPickup: false,
      invoiceNumber: '',
      hazmat: '',

      // international information
      contentsType: '',
      contentsExplanation: '',
      customsCertify: false, // electronically certify customs info
      customSigner: '', // who is certifying it
      nonDeliveryOption: 'return',
      restrictionType: 'none',
      restrictionComment: '', // required if Type is not 'none'
      customItemIds: [], // the easy post ids of the custom items being shipped
      aesNumber: '',

      // customs items data-table config
      customsItemsHeaders: [
        { text: 'Include in Box', value: 'selected' },
        { text: 'Part Number', value: 'pn' },
        { text: 'Description', value: 'description' },
        { text: 'Harmonized Code', value: 'hs_tariff_number' },
        { text: 'Total Value', value: 'value' },
        { text: 'Total Quantity', value: 'quantity' },
        { text: 'Total Weight', value: 'weight' },
        { text: 'Origin Country', value: 'origin_country' }
      ],
      customsItems: [],
      customsItemsError: '',
      selected: [],
      skipItemsQuery: true
    }
  },
  apollo: {
    order: {
      query: GET_SHIPMENT_ORDER_DETAILS__FULL,
      variables () {
        return {
          id: this.id
        }
      },
      update (data) {
        if (data) {
          if (data.order.isInternational) {
            this.skipItemsQuery = false
            freshDeskWidget?.bump()
          }
          if (data.order.service) {
            if (
              !data.order.comments
                .toLowerCase()
                .includes(data.order.service.service.toLowerCase())
            ) {
              data.order.comments =
                `${data.order?.service?.shipper?.name ?? ''} ${
                  data.order?.service?.service ?? ''
                }\n` + data.order.comments
            }
          }
          this.serviceEasypostName = data.order.service?.easypost_name
          this.getRatesForShipments()
          this.dataIsSet = true
          return data.order
        }
      },
      fetchPolicy: 'network-only'
    },
    customsItems: {
      query: GET_SOLD_ITEMS_FOR_CUSTOMS,
      variables () {
        return {
          id: this.order.sale.id
        }
      },
      skip () {
        return this.skipItemsQuery
      },
      update (data) {
        const items = []
        data.items.forEach((i) => {
          const index = items.findIndex((ii) => {
            return ii.pn === i.part.pn
          })
          if (index === -1) {
            items.push({
              pn: i.part.pn,
              description: this.getItemDescription(i.part),
              hs_tariff_number: this.getItemTariffCode(i.part),
              value: Number(i.sold_for.amount),
              quantity: 1,
              origin_country: this.originCountry,
              weight: ''
            })
          } else {
            items[index].quantity++
            items[index].value = items[index].value + Number(i.sold_for.amount)
          }
        })
        return items
      }
    },
    shipments: {
      query: GET_SHIPMENTS_ON_SHIPPING_ORDER,
      variables () {
        return {
          id: this.id
        }
      },
      update (data) {
        return data.shipping_shipments.map((s) => {
          s.rate = { id: '', rate: '' }
          s.loadingRate = false
          s.isPrintingCommercialInvoice = false
          s.isPrintingLabel = false
          return s
        })
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    ...mapActions('notifications', {
      createSnackbar: 'createSnackbar'
    }),

    async createBox () {
      // information required on every box
      const box = {
        options: {
          additional_handling: this.additionalHandling,
          delivery_confirmation: this.deliveryConfirmation,
          dropoff_type: this.easyPostDropOffType,
          hold_for_pickup: this.holdForPickup,
          invoice_number: this.invoiceNumber,
          ...(this.hazmat && { hazmat: this.hazmat }),
          label_format: 'ZPL',
          machinable: true,
          payment: {
            type: this.easyPostPaymentType,
            account: this.order.account.ep,
            country: this.order.billTo.country.name,
            postal_code: this.order.billTo.zip_code
          }
        },
        order_id: this.id,
        box_number: this.nextBoxNumber,
        predefined_parcel: this.predefinedParcel,
        release_number: this.releaseNumber,
        parcel_height_in: this.predefinedParcel ? 0 : this.height,
        parcel_length_in: this.predefinedParcel ? 0 : this.length,
        parcel_width_in: this.predefinedParcel ? 0 : this.width,
        parcel_weight_oz: this.weightOunces
      }
      // if order is international
      if (this.order.isInternational) {
        if (this.customsItemsValidator()) {
          const customsItems = []
          this.selected.forEach((s) => {
            customsItems.push(JSON.parse(JSON.stringify(s)))
          })
          const customsInfo = {
            contents_explanation: this.contentsExplanation,
            contents_type: this.contentsType,
            customs_certify: this.customsCertify,
            ...(this.customsCertify && { customs_signer: this.customSigner }),
            non_delivery_option: this.nonDeliveryOption,
            restriction_type: this.restrictionType,
            ...(this.restrictionType !== 'none' && {
              restriction_comment: this.restrictionComment
            }),
            customs_items: customsItems.map((item) => {
              delete item.pn
              item.weight = item.weight * 16
              return item
            })
          }
          if (this.aesNumberNeeded) {
            customsInfo.eel_pfc = this.aesNumber
          } else {
            customsInfo.eel_pfc = 'NOEEI 30.37(a)'
          }
          try {
            box.easypost_customs_info_id = await this.createEasyPostCustomsInfo(
              { customsInfo: customsInfo }
            )
            return box
          } catch (error) {
            throw new Error(error)
          }
        } else {
          throw new Error('Failed validation. Fix errors to continue.')
        }
      } else {
        return box
      }
    },

    // box management methods
    async addBox () {
      if (this.$refs.newBox.validate()) {
        this.addingBox = true
        try {
          const box = await this.createBox()
          // add either predefined or custom box
          const newBox = !this.usePredefined
            ? await this.addBoxToShipment({ box: box })
            : await this.addEasyPostPredefinedBoxToShipment({ box: box })

          // add box to shipments list
          newBox.rate = { id: '', rate: '' }
          newBox.loadingRate = false
          this.shipments.push(newBox)
          await this.getRatesForShipments()

          // result handling
          const message = 'Successfully added box to shipment order.'
          this.boxResultHandler({ message: message, type: 'success' })
        } catch (error) {
          const message = error.message ? error.message : error
          this.boxResultHandler({ message: message, type: 'error' })
        }
      }
    },

    async getRatesForShipments () {
      for (const shipment of this.shipments) {
        if (!shipment.rate.id && shipment.easypost_id) {
          shipment.loadingRate = true
          shipment.rate = await GetShipmentRateForService(
            shipment.easypost_id,
            this.serviceEasypostName
          )
          shipment.loadingRate = false
        }
      }
    },

    async printShipmentCommercialInvoice (shipment) {
      try {
        Vue.set(shipment, 'isPrintingCommercialInvoice', true)
        const url = await GetEasyPostShipmentCommercialInvoice(
          shipment.easypost_id
        )
        const job = await CreatePrintJob({
          printer: this.printer,
          content: url,
          type: Printnode_Content_Type.PdfUri,
          title: `Commercial Invoice for shipment order: ${this.order.id}`
        })
        if (job.code === 'BadRequest' && job.message) {
          this.commercialInvoiceHandler({
            message: job.message,
            type: 'error'
          })
        }
      } catch (error) {
        const message = error.message ? error.message : 'Invoice unavailable.'
        this.commercialInvoiceHandler({ message: message, type: 'error' })
      } finally {
        Vue.set(shipment, 'isPrintingCommercialInvoice', false)
      }
    },

    async printShipmentLabel (shipment) {
      try {
        Vue.set(shipment, 'isPrintingLabel', true)
        const url = await GetEasyPostShipmentLabel(shipment.easypost_id)
        await CreatePrintJob({
          content: url,
          type: Printnode_Content_Type.RawUri,
          printer: this.printer,
          quantity: 1,
          title: `Shipment Label for shipment ${shipment.id}`
        })
      } catch (e) {
        this.$store.dispatch('notifications/createSnackbar', {
          message: e.message ? e.message : 'Could not print label',
          color: 'error'
        })
      } finally {
        Vue.set(shipment, 'isPrintingLabel', false)
      }
    },

    /**
     * Removes a box from the shipment order
     * @param {Object} obj
     * @param {Object} obj.box
     * @param {String || Number} box.id
     * @param {Boolean} box.confirm
     */
    async removeBox ({ box }) {
      if (box.confirm) {
        Vue.set(box, 'removing', true)
        try {
          await this.deleteBoxFromShipment({ id: box.id })
          const message = 'Removed box '
          const index = this.getBoxIndex({ id: box.id })
          this.shipments.splice(index, 1)
          this.boxResultHandler({ message: message })
        } catch (error) {
          this.boxResultHandler({ message: error, type: 'error' })
          Vue.set(box, 'removing', false)
        }
      } else {
        Vue.set(box, 'confirm', true)
        setTimeout(function () {
          Vue.set(box, 'confirm', false)
        }, 3000)
      }
    },
    // end box management methods

    // result handler methods
    /**
     * Result Handler for any async methods done in this component
     * @param message
     * @param type
     * @param action
     */
    boxResultHandler ({ message, type = 'success', action = 'addingBox' }) {
      this.createSnackbar({
        message: message,
        color: type,
        timeout: 4000
      })
      this[action] = false
      if (type === 'success') {
        this.resetInputs()
      }
    },

    commercialInvoiceHandler ({ message, type }) {
      this.createSnackbar({
        message: message,
        color: type,
        timeout: 4000
      })
    },

    processResultHandler ({ message, type = 'success' }) {
      this.createSnackbar({
        message: message,
        color: type,
        timeout: 4000
      })
      this.processing = false
      if (type === 'success') {
        this.succeeded = true
      }
    },
    // end result handler methods

    resetInputs () {
      this.$refs.newBox.reset()
      setTimeout(() => {
        this.deliveryConfirmation = this.deliveryConfirmations[0]
        this.dropOffType = this.dropOffTypes[0]
      }, 200)
    },

    async process () {
      if (
        this.shipmentOrderValidator() &&
        this.order.pickup_type === 'NOT_PICKUP'
      ) {
        this.processing = true
        try {
          const labels = await BuyEasyPostShipments(
            this.shipments,
            this.order.id
          )
          // do something with labels
          await PrintLabelsFromUri(this.printer, labels)
          this.processResultHandler({
            message: 'Processed shipment successfully!',
            type: 'success'
          })
        } catch (error) {
          const message = error.message ? error.message : error
          this.processResultHandler({ message: message, type: 'error' })
        }
      }
    },

    async refund () {
      this.refunding = true
      try {
        await RefundEasyPostShipments(this.shipments)
        await this.updateShipmentOrderStatus(
          this.order.id,
          SHIPMENT_ORDER_STATUS.TO_PROCESS
        )
      } catch (e) {
        this.processResultHandler({ message: e.message, type: 'error' })
      } finally {
        this.refunding = false
      }
    },

    async getShipmentsEasyPostIds () {
      try {
        const shipments = []
        // get all the shipment objects needed to hand off to easy post
        for (const shipment of this.shipments) {
          const epShipmentResponse = await this.$apollo.query({
            query: GET_EASY_POST_SHIPMENT_FULL,
            variables: { id: shipment.easypost_id }
          })
          const epShipment =
            epShipmentResponse.data.EasyPost_RetrieveShipment.easypost_id
          shipments.push(epShipment)
        }
        return shipments
      } catch {
        throw new Error('Could not retrieve shipment information for order.')
      }
    },

    /**
     * Gets the full easypost shipment details
     * @param {EasyPostId} id the easypost id of the shipment
     * @return {Promise<Object | null>}
     */
    async getEasyPostShipmentDetails (id) {
      return (
        this.$apollo.query({
          query: GET_EASY_POST_SHIPMENT_FULL,
          variables: { id: id }
        })?.data?.EasyPost_RetrieveShipment ?? null
      )
    },

    findBestRateBasedOnUserInput (order) {
      let carrier = ''
      let service = ''
      // If a user has pre-selected a service to use
      if (this.order.service) {
        carrier = this.order.account.shipper.name
        service = this.order.service.service
      } else {
        // if there is a deliver by date check for rates that deliver the product by that date, or just find cheapest
        // of all rates
        const ratesToCheck = this.order.deliverBy
          ? order.rates.filter((r) =>
            dateIsSameOrBefore({ dateA: r.deliverBy, dateB: this.order.deliverBy }))
          : order.rates

        // now find cheapest out of these rates
        const indexOfCheapest = ratesToCheck.reduce(
          (indexOfMin, e, i, a) =>
            e.rate < a[indexOfMin].rate ? i : indexOfMin,
          0
        )
        carrier = ratesToCheck[indexOfCheapest].carrier
        service = ratesToCheck[indexOfCheapest].service
      }

      return { carrier, service }
    },

    getBoxIndex ({ id }) {
      return this.shipments.findIndex((s) => {
        return s.id === id
      })
    },

    getItemTariffCode (item) {
      let code = ''
      if (item.htsus_code) {
        code = item.htsus_code
      } else if (item.hs_code) {
        code = item.hs_code
      } else {
        return ''
      }
      if (code.contains('.')) {
        code = code.replace(/\./g, '')
      }
      return code.length > 6 ? code.substring(0, 5) : code
    },

    getItemDescription (item) {
      if (item.type && item.type.toLowerCase() !== 'part') {
        return item.type
      } else {
        return item.description
      }
    },

    getReturnAddress () {
      const returnToEpId = this.order.returnTo?.easypost_id ?? false
      const shipFromEpId = this.order.shipFrom.easypost_id
      return returnToEpId || shipFromEpId
    },

    shipmentOrderValidator () {
      if (this.shipments.length === 0) {
        this.processResultHandler({
          message: 'A shipment requires boxes to process.',
          type: 'warning'
        })
        return false
      }
      if (!this.printer) {
        this.processResultHandler({
          message: 'You must select a printer to print labels',
          type: 'warning'
        })
        return true
      }
      // if (!this.printer) {
      //   this.processResultHandler({ message: 'Select a printer to print from.', type: 'error' })
      //   return false
      // }
      return true
    },

    customsItemsValidator () {
      for (const item of this.selected) {
        if (!item.hs_tariff_number || !item.weight) {
          this.customsItemsError =
            'Each customs item must have all information filled out to process.'
        }
      }
      if (!this.selected) {
        this.customsItemsError =
          'You must assign customs items to a box, check the boxes of the items that match the packaged parts for this box.'
      }
      const validated = this.$refs.newBox.validate()
      return this.customsItemsError === '' && validated
    },

    focusElement (el) {
      setTimeout(function () {
        document.querySelector(`#${el}`).focus()
      }, 200)
    },

    unFocusElement (el) {
      document.querySelector(`#${el}`).blur()
    }
  },
  beforeDestroy () {
    if (this.order.isInternational) {
      freshDeskWidget?.unBump()
    }
  }
}
</script>
