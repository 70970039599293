import gql from 'graphql-tag'
import { DocumentNode } from 'apollo-link'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  Easypost_Form_Type,
  EasyPostForm, EasyPostRate,
  Mutation,
  MutationEasyPost_BuyShipmentArgs,
  MutationEasyPost_RefundShipmentArgs,
  MutationUpdate__Shipping_ShipmentOrderArgs, Query,
  QueryEasyPost_RetrieveShipmentArgs,
  Shipping_Shipment
} from '@/models/generated/graphql/ErpBackend'
import { UPDATE_SHIPMENT_ORDER } from '@/api/graphql/Constants/Shipments'
import { SHIPMENT_ORDER_STATUS } from '@/models/ExtraBackendModels'

/* FRAGMENTS */

export const FRAGMENT__EASY_POST_CUSTOMS_ITEM__FULL: DocumentNode = gql`
  fragment EasyPostCustomsItems_Full on EasyPostCustomsItem {
    code
    currency
    description
    hs_tariff_number
    id
    object
    origin_country
    quantity
    value
    weight
  }
`

export const FRAGMENT__EASY_POST_CUSTOMS_INFO__FULL: DocumentNode = gql`
  fragment EasyPostCustomsInfo_Full on EasyPostCustomsInfo {
    contents_explanation
    contents_type
    customs_certify
    customs_items {
      ...EasyPostCustomsItems_Full
    }
    customs_signer
    eel_pfc
    id
    object
    restriction_comments
    restriction_type
  }
  ${FRAGMENT__EASY_POST_CUSTOMS_ITEM__FULL}
`

export const FRAGMENT__EASY_POST_PARCEL__FULL = gql`
  fragment EasyPostParcel_Full on EasyPostParcel {
    height
    id
    length
    mode
    predefined_package
    weight
    width
  }
`

export const FRAGMENT__EASY_POST_SHIPMENT__FULL: DocumentNode = gql`
  fragment EasyPostShipment_Full on EasyPostShipment {
    id
    customs_info {
      ...EasyPostCustomsInfo_Full
    }
    forms {
      form_url
      id
      form_type
      submitted_electronically
    }
    options {
      additional_handling
      delivery_confirmation
      dropoff_type
      hold_for_pickup
      invoice_number
      hazmat
      label_format
      machinable
      payment {
        type
        account
        country
        postal_code
      }
    }
    parcel {
      ...EasyPostParcel_Full
    }
    refund_status
    postage_label {
      id
      label_pdf_url
      label_epl2_url
      label_zpl_url
      label_url
    }
    reference
  }
  ${FRAGMENT__EASY_POST_CUSTOMS_INFO__FULL}
  ${FRAGMENT__EASY_POST_PARCEL__FULL}
`

export const FRAGMENT__EASY_POST_CARRIER_ACCOUNT__FULL: DocumentNode = gql`
  fragment EasyPostCarrierAccount__Full on EasyPostCarrierAccount {
    clone
    created_at
    credentials { account_number }
    description
    id
    object
    readable
    reference
    type
    updated_at
  }
`

/* HELPERS */

/* QUERIES */

export const GET_EASY_POST_SHIPMENT_FULL: DocumentNode = gql`
  query GetEasyPostShipment_Full ($id: ID!) {
    EasyPost_RetrieveShipment (id: $id) {
      ...EasyPostShipment_Full
    }
  }
  ${FRAGMENT__EASY_POST_SHIPMENT__FULL}
`

export const GET_EASYPOST_SHIPMENT_RATES = gql`
  query Get_EasyPost_Shipment_Rates ($id: ID!) {
    EasyPost_RetrieveShipment(id: $id) {
      id
      rates {
        id
        rate
        list_rate
        retail_rate
        service
        carrier
      }
      postage_label {
        id
        label_pdf_url
        label_epl2_url
        label_zpl_url
        label_url
      }
    }
  }
`

export const GET_EASYPOST_CARRIER_ACCOUNTS = gql`
  query GetEasypostCarrierAccounts {
    EasyPost_ListCarrierAccounts {
      ...EasyPostCarrierAccount__Full
    }
  }
  ${FRAGMENT__EASY_POST_CARRIER_ACCOUNT__FULL}
`

/* MUTATIONS */

export const BUY_EASY_POST_SHIPMENT: DocumentNode = gql`
  mutation BuyEasyPostShipment ($id: EasyPostID!, $rate: EasyPostID, $insurance: Price) {
    EasyPost_BuyShipment (id: $id, rate: $rate, insurance: $insurance) {
      id
      postage_label {
        id
        label_pdf_url
        label_epl2_url
        label_zpl_url
        label_url
      }
      tracking_code
    }
  }
`

export const REFUND_EASY_POST_SHIPMENT: DocumentNode = gql`
  mutation RefundEasyPostShipment ($id: EasyPostID!) {
    EasyPost_RefundShipment(id: $id) {
      id
    }
  }
`

export const CREATE_EASYPOST_FEDEX_CARRIER_ACCOUNT = gql`
  mutation CreateEasypostFedExCarrierAccount ($input: EasyPost_RegisterFedexAccountInput!) {
    EasyPost_RegisterFedExAccount(input: $input)
  }
`

export const CREATE_EASYPOST_UPS_CARRIER_ACCOUNT = gql`
  mutation CreateEasypostUPSCarrierAccount ($input: EasyPost_RegisterUPSAccountInput!) {
    EasyPost_RegisterUPSAccount(input: $input)
  }
`

export const CREATE_EASYPOST_CARRIER_ACCOUNT: DocumentNode = gql`
  mutation CreateEasypostCarrierAccount ($input: EasyPost_MakeCarrierAccountInput!) {
    EasyPost_MakeCarrierAccount(input: $input) {
      id
    }
  }
`

/* METHOD */

export interface UI_Shipping_Shipment {
  box_number: number,
  easypost_id: string,
  height: number,
  id: number,
  index: number,
  length: number,
  loadingRate: boolean,
  predefined: string,
  rate: UI_RateObject,
  weight: number,
  width: number
}

export interface UI_RateObject {
  id: string,
  rate: string
}

interface UI_IdLabelUri_ForShipment {
  id: number,
  uri: string
}

export async function BuyEasyPostShipments (shipments: UI_Shipping_Shipment[], orderId: string): Promise<UI_IdLabelUri_ForShipment[]> {
  try {
    const labelUrls: UI_IdLabelUri_ForShipment[] = []
    for (const shipment of shipments) {
      const response = await apollo.mutate<Mutation, MutationEasyPost_BuyShipmentArgs>({
        mutation: BUY_EASY_POST_SHIPMENT,
        variables: { id: shipment.easypost_id, rate: shipment.rate.id, insurance: '0.01' }
      })
      if (response.data?.EasyPost_BuyShipment?.postage_label?.label_zpl_url) {
        labelUrls.push({ id: shipment.id, uri: response.data?.EasyPost_BuyShipment?.postage_label?.label_zpl_url })
      }
    }
    await apollo.mutate<Mutation, MutationUpdate__Shipping_ShipmentOrderArgs>({
      mutation: UPDATE_SHIPMENT_ORDER,
      variables: { input: { id: orderId, status_id: SHIPMENT_ORDER_STATUS.AWAITING_CARRIER } }
    })
    return labelUrls
  } catch {
    throw new Error('Could not purchase shipments')
  }
}

export async function RefundEasyPostShipments (shipments: Shipping_Shipment[]): Promise<void> {
  try {
    for (const shipment of shipments) {
      await apollo.mutate<Mutation, MutationEasyPost_RefundShipmentArgs>({
        mutation: REFUND_EASY_POST_SHIPMENT,
        variables: { id: shipment.easypost_id }
      })
    }
  } catch (error) {
    throw new Error(error.networkError)
  }
}

export async function GetEasyPostShipmentCommercialInvoice (id: string): Promise<string> {
  const response = await apollo.query<Query, QueryEasyPost_RetrieveShipmentArgs>({
    query: GET_EASY_POST_SHIPMENT_FULL,
    variables: { id: id }
  })
  const forms: EasyPostForm[] = response.data.EasyPost_RetrieveShipment?.forms ?? []
  if (forms.length > 0) {
    const invoice = forms.find(f => f.form_type === Easypost_Form_Type.CommercialInvoice)
    if (invoice) {
      if (invoice.form_url) {
        return invoice.form_url
      }
    }
  }
  throw new Error('Commercial invoice for this shipment is unavailable.')
}

export async function GetEasyPostShipmentLabel (id: string): Promise<string> {
  const response = await apollo.query<Query, QueryEasyPost_RetrieveShipmentArgs>({
    query: GET_EASY_POST_SHIPMENT_FULL,
    variables: { id: id }
  })
  const label: string | undefined = response.data.EasyPost_RetrieveShipment?.postage_label?.label_zpl_url
  if (label) {
    return label
  }
  throw new Error('Could not retrieve label')
}

export async function GetShipmentRateForService (id: string, easypostServiceName: string): Promise<UI_RateObject> {
  const response = await apollo.query<Query, QueryEasyPost_RetrieveShipmentArgs>({
    query: GET_EASYPOST_SHIPMENT_RATES,
    variables: { id: id },
    fetchPolicy: 'no-cache'
  })
  const rates: EasyPostRate[] = response.data.EasyPost_RetrieveShipment?.rates ?? []
  const rate = rates.find((r) => r.service === easypostServiceName)
  if (rate) {
    return { id: rate.id, rate: rate.rate ?? '-' }
  } else {
    return { id: '', rate: 'ERROR' }
  }
}
